.package-selector-container {
  display: flex;
  min-width: 300px;
  padding: 12px;
  align-items: flex-start;
  gap: 12px;
  border-radius: 4px;
  border: 1px solid #f0f0f0;

  .checkbox-container {
    display: flex;
    width: 16px;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
  }

  .package-selector-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    flex: 1 0 0;

    .package-name {
      color: #000;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px;
    }

    .package-rating {
      display: flex;
      align-items: center;
      gap: 4px;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px;

      .rating {
        color: #faad14;
        display: flex;
        align-items: center;
        flex-direction: row;
        gap: 4px;
      }

      .nb-feedbacks {
        color: #8c8c8c;
      }
    }
  }
}

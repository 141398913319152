.body {
  display: flex;
  flex-direction: column;
  gap: 24px;

  .packages-review-summary {
    position: relative;
    display: flex;
    align-items: center;

    .packages-list {
      display: flex;
      flex-direction: row;
      gap: 16px;
      overflow: auto;
      -ms-overflow-style: none;
      scrollbar-width: none;
    }

    .packages-list::-webkit-scrollbar {
      display: none;
    }

    .scroll-button-container {
      position: absolute;
      z-index: 1;
      display: flex;
      align-items: center;
      height: 100%;

      .scroll-button {
        background: #fff;
        padding: 4px 10px;
      }

      &.scroll-right {
        right: 0px;
        background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #fff 80%);
      }

      &.scroll-left {
        left: 0px;
        background: linear-gradient(
          90deg,
          #fff 20%,
          rgba(255, 255, 255, 0) 100%
        );
      }
    }
  }

  .bottom-pagination {
    padding-bottom: 16px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
}

.hotel-marks {
  display: flex;
  align-items: center;
  gap: 4px;

  .star {
    color: #faad14;
    font-size: 16px;
  }

  .average {
    margin-left: 4px;
    line-height: 19px;
    color: #faad14;
    font-size: 16px;
    font-weight: 500;
  }

  .counter {
    line-height: 19px;
    color: #8c8c8c;
    font-size: 16px;
    font-weight: normal;
  }
}
